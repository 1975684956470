import React from "react";

function article_1() {
	return {
		date: "2023-10-20",
		title: "Bonfire 2023",
		description:
			"",
		keywords: [
			"",
		],
		pic_path: "./bonfire.jpg",
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				
				p {
					font-family: 'Microsoft JhengHei', sans-serif;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						<p>
						Content of article 1
						</p>
						
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "2023-12",
		title: "2023",
		description:
			"",
			style: `
			.article-content {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.randImage {
				align-self: center;
				outline: 2px solid red;
			}
			
			p {
				font-family: 'Microsoft JhengHei', sans-serif;
			}
			`,
		keywords: [
			"",
		],
		pic_path: "./dartmouthhall.jpg",
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						<p>
						关于二〇二四我很难做什么计划，我太年轻，时代变得太快。有太多的外力都比我的意愿重要得多。我希望春招会好些，那么我会在加州纽约，或者任何一个给我一份工作的地方；也许不会，那么我大概在北京或者上海。试图猜测几个月以后我在做什么就像掰着手指算大矩阵一样搞笑，我只知道我会是在没有猜到的地方做着没有猜到的事。你管这个叫什么呢？迷茫，或者自由。</p>
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_2];

export default myArticles;
